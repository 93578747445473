app.factory('executionService', [
    '$http', '$location', 'fileService', 'sharedSessionService', 'ultimateOpenIdService', function ($http, $location, fileService, sharedSessionService, ultimateOpenIdService) {

        var service = {};

        const verbs = {
            POST: 'post',
            GET: 'get',
            DELETE: 'delete',
            FORMDATA: 'formdata'
        };

        /**
        * Portal flow menu workflow state - you can use in memory storage (service) or local storage instead.
        */
        var currentWorkflowState = {};

        var getStateId = function (portletId) {

            if (!portletId) {
                portletId = 0;
            }

            var user = sharedSessionService.getUser();
            return user + "_" + portletId;
        };

        service.setCurrentWorkflowState = function (jobIdentity, workflowHeader, inbox, portletId) {
            var ibx = inbox || false;
            var currentWorkflowStateObj = {
                jobIdentity: jobIdentity,
                workflowHeader: workflowHeader,
                inbox: ibx
            };
            currentWorkflowState[getStateId(portletId)] = currentWorkflowStateObj;
        };

        service.getCurrentWorkflowState = function (portletId) {
            return currentWorkflowState[getStateId(portletId)];
        };

        service.resetCurrentWorkflowState = function (portletId) {
            currentWorkflowState[getStateId(portletId)] = null;
        };

        /**
         * Portal inbox workflow state - you can use in memory storage (service) or local storage instead.
         */
        var currentInboxWorkflowState = {};

        service.setCurrentInboxWorkflowState = function (executionIdentity, handoverIdentity, themeColor, text, icon, portletId) {
            var currentInboxWorkflowStateObj = {
                executionIdentity: executionIdentity,
                handoverIdentity: handoverIdentity,
                name: text,
                icon: icon,
                portletId: portletId
            };

            currentInboxWorkflowState[getStateId(portletId)] = currentInboxWorkflowStateObj;
        };

        service.getCurrentInboxWorkflowState = function (portletId) {
            return currentInboxWorkflowState[getStateId(portletId)];
        };

        service.resetCurrentInboxWorkflowState = function (portletId) {
            currentInboxWorkflowState[getStateId(portletId)] = null;
        };

        service.start = function (workflowIdentity, removePreviousJob) {
            return callApi(verbs.GET, $.path + "/api/start/" + workflowIdentity + "/" + removePreviousJob);
        };

        service.startWithParameters = function (data) {
            return callApi(verbs.POST, $.path + "/api/startWithParameters", data);
        };

        service.startWithTableParameters = function (data) {
            return callApi(verbs.POST, $.path + "/api/workflows/user/tableParameters", data);
        };

        service.startWithTableAndOperationParameters = function (data) {
            return callApi(verbs.POST, $.path + "/api/workflows/user/tableAndOperationParameters", data);
        };


        service.handover = function (executionIdentity) {
            return callApi(verbs.GET, $.path + "/api/handover/" + executionIdentity);
        };

        service.resume = function (executionIdentity) {
            return callApi(verbs.GET, $.path + "/api/resume/" + executionIdentity);
        };

        service.takeJob = function (handoverIdentity) {
            return callApi(verbs.POST, $.path + "/api/takeJob", { handoverIdentity: handoverIdentity });
        };

        service.moveNext = function (data) {
            return callApi(verbs.FORMDATA, $.path + "/api/moveForward", data);
        };

        service.refresh = function (data) {
            return callApi(verbs.POST, $.path + "/api/refresh", data);
        };

        service.pauseJob = function (data) {
            return callApi(verbs.POST, $.path + "/api/pause", data);
        };

        service.moveBack = function (data) {
            return callApi(verbs.POST, $.path + "/api/moveBack", data);
        };

        service.cancelJob = function (data) {
            return callApi(verbs.POST, $.path + "/api/cancel", data);
        };

        service.startSubworkflow = function (data) {
            return callApi(verbs.POST, $.path + "/api/subworkflowExecution", data);
        };

        service.getSubTaskCheckItems = function (jobIdentity, subTaskIdentity) {
            return callApi(verbs.GET, $.path + "/api/checkListSubTask/" + jobIdentity + "/" + subTaskIdentity);
        };

        service.startCheckItem = function (data) {
            return callApi(verbs.POST, $.path + "/api/startCheckItem", data);
        };

        service.getItemCreations = function (jobIdentity, subTaskIdentity) {
            return callApi(verbs.GET, $.path + "/api/itemCreationSubTask/" + jobIdentity + "/" + subTaskIdentity);
        };

        service.resumeItemCreation = function (data) {
            return callApi(verbs.POST, $.path + "/api/resumeItemCreationSubTask", data);
        };

        service.removeItemCreation = function (jobIdentity, subTaskIdentity, itemIndex) {
            return callApi(verbs.DELETE, $.path + "/api/removeItemCreationSubTask/" + jobIdentity + "/" + subTaskIdentity + "/" + itemIndex);
        };

        callApi = function (verb, url, data) {

            switch (verb) {
                case verbs.GET:
                    return $http.get(url);
                case verbs.POST:
                    return $http.post(url, data);
                case verbs.DELETE:
                    return $http.delete(url);
                case verbs.FORMDATA:
                    var formdata = new FormData();
                    formdata.append("model", angular.toJson(data));

                    var files = fileService.getAll();

                    angular.forEach(files, function (value, key) {
                        formdata.append(key, value);
                    });

                    var request = {
                        method: 'POST',
                        url: url,
                        data: formdata,
                        headers: {
                            'Content-Type': undefined
                        }
                    };

                    fileService.clear();

                    return $http(request);
            }
        };

        return service;
    }
]);