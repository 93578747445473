app.factory('sharedSessionService', [
	'localizationService', function (localizationService) {
		
		var service = {};
		service.sharedSession = null;
		
		service.set = function (sharedSession) {
			service.sharedSession = sharedSession;
		};
		
		service.update = function (sharedSession) {
			service.sharedSession = sharedSession;
		};
		
		service.exists = function () {
			if (service.sharedSession === null) {
				return false;
			} else {
				return true;
			}
		};

		service.reset = function () {
			service.sharedSession = null;
			localizationService.reset();
		};
		

		service.getSittingKey = function () {
			if (service.sharedSession === null) {
				return "";
			}

			return service.sharedSession.SittingKey;
		};
		

		service.getSessionKey = function () {
			if (service.sharedSession === null) {
				return "";
			}
			return service.sharedSession.SessionKey;
		};

		service.getUserName = function () {
			if (service.sharedSession === null) {
				return "";
			}
			return service.sharedSession.UserName;
		};

		service.getUser = function () {
			if (service.sharedSession === null) {
				return "";
			}
			return service.sharedSession.User.toLowerCase();
		};

		service.getLanguageCode = function () {
			if (service.sharedSession === null) {
				return "";
			}

			return service.sharedSession.LanguageCode;
		};
		service.getCulture = function () {

			if (service.sharedSession === null || service.sharedSession.Culture === null) {
				return $.culture;
			}
			return service.sharedSession.Culture;
		};
		service.getDeviceId = function () {

			if (service.sharedSession === null || service.sharedSession.DeviceId === null) {
				return "";
			}
			return service.sharedSession.DeviceId;
		};


		// Confirmed with Marcus S that this is used for link execution (we will not get to this point)
		service.isPublicSession = function () {

			return false;
		};

		service.isOpenIdSession = function () {

			return false;
		};

		return service;
	}
]);