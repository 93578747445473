app.factory('webExceptionHandlingService', ['$rootScope', function ($rootScope) {

    var service = {};

    service.statusText = "";

    service.displayErrorNotification = function (message, errorStack) {   
        var toDisplay = {};
        toDisplay.DetailText = "";
        toDisplay.TechnicalInfo = "";
        if (errorStack && typeof errorStack === 'object') {
            toDisplay.DetailText = errorStack.DetailText;
            toDisplay.TechnicalInfo = errorStack.TechnicalInfo;
        }
        else if (errorStack) {
            toDisplay.TechnicalInfo = errorStack;
        }   
        $rootScope.$broadcast("showError", { errorMessage: message, detailText: toDisplay.DetailText, technicalInfo: toDisplay.TechnicalInfo, statusText: service.statusText });

    }
    service.displaySimpleErrorNotificationOnce = function (message) {
       
        $rootScope.$broadcast("showErrorOnce", { errorMessage: message, detailText: "", technicalInfo: "", statusText: service.statusText });

    }
    service.displaySimpleErrorNotification = function (message, details) {             
        $rootScope.$broadcast("showError", { errorMessage: message, detailText: details, technicalInfo: '', statusText: ''}); //if no status text is provided the error template will crash when compiled
    }

    service.displayWarningNotification = function (message) {
        $rootScope.$broadcast("showWarning", { warningMessage: message });
    }

    return service;
}]);