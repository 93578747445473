app.factory('webHttpInterceptor', [
    '$q', '$injector', '$rootScope', '$location', 'webExceptionHandlingService', 'sharedSessionService', 'fingerPrintService',
    function ($q, $injector, $rootScope, $location, webExceptionHandlingService, sharedSessionService, fingerPrintService) {

        $rootScope.isThiefModalOpened = false;

        var requestBlackList = [$.path + "/api/calculateCalendar", $.path + "/api/updateSelectedDate"];

        function showOrHideThief(message) {

            if (!$rootScope.isThiefModalOpened) {

                var modal = $injector.get('$uibModal');
                $rootScope.isThiefModalOpened = true;

                var modalInstance = modal.open({
                    templateUrl: $.sharedAppDir + "/views/takeBackSitting.html",
                    controller: 'takeBackSittingController',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        errorMessage: function () {
                            return message;
                        },
                        routeReload: function () {

                            if ($location.absUrl().indexOf('execution') > -1) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    }

                });
                modalInstance.result.then(function (modalData) {
                    if (modalData) {
                        if (modalData.reloadMenu) {
                            $rootScope.$broadcast('ReloadMenus', 'Reload');
                        }
                    }
                });
            }
        };

        function manageOfInternalServerError(rejection) {
            var progressService = $injector.get('progressService');

            var errorMessageObject = {};
            var message = "";
            var techMessage = "";

            try {
                errorMessageObject = JSON.parse(rejection.data.ResponseStatus.Message);
            } catch (error) {
                message = rejection.data ? rejection.data.ResponseStatus.Message : rejection.statusText;

                errorMessageObject = {
                    DetailedText: message,
                    RootComponent: 3
                };
            }

            if (errorMessageObject.Reason) {
                message = errorMessageObject.Reason;
            }
            if (errorMessageObject.DetailedText) {
                message = message + errorMessageObject.DetailedText;
            }
            if (message === "") {
                message = "Unknown error";
            }
            if (errorMessageObject.RootComponent === 0) {
                techMessage += " Source: Web Client";
            }
            if (errorMessageObject.RootComponent === 3) {
                techMessage += " Source: Flow Server";
            }

            if (errorMessageObject.TechnicalInfo) {
                try {
                    var techInfoObject = JSON.parse(errorMessageObject.TechnicalInfo);
                    techMessage +=
                        "\n" + techInfoObject.DetailText + "\n" + techInfoObject.TechnicalInfo;
                } catch (error) {
                    techMessage += "\n" + errorMessageObject.TechnicalInfo;
                }
            }

            webExceptionHandlingService.displayErrorNotification(message, techMessage);

            progressService.close();
        }

        var service = {
            'request': function (request) {
                if (
                    request.url.lastIndexOf($.sharedAppDir + "/views", 0) !== 0 && request.url.lastIndexOf("templates", 0) !== 0) {

                    request.headers['User'] = encodeURIComponent(sharedSessionService.getUser());
                    request.headers['Language'] = $.language;
                    request.headers['SESSION_KEY'] = sharedSessionService.getSessionKey();
                    request.headers['SITTING_KEY'] = sharedSessionService.getSittingKey();
                    request.headers['DEVICE_ID'] = sharedSessionService.getDeviceId();

                    if (requestBlackList.indexOf(request.url) === -1) {
                        var progressService = $injector.get('progressService');
                        progressService.open();
                    }
                }

                return request;
            },
            'response': function (response) {

                if (response.config.url.lastIndexOf($.sharedAppDir + "/views", 0) !== 0  && response.config.url.lastIndexOf("templates", 0) !== 0) {
                    if (requestBlackList.indexOf(response.config.url) === -1) {
                        var progressService = $injector.get('progressService');
                        progressService.close();
                    }
                }
                if (!response.data.Failure && response.config.responseType !== "arraybuffer" && typeof(response.data) !== 'string') {
                    var data = JSON.stringify(response.data);
                    if (data) {
                        response.data = JSON.parse(data.replace(/<\s*?\/?\s*?script\b[^>]*>/ig, ''));
                    }
                }
                if (response.data.Failure) {
                    var stackTrace = response.data.Failure.DetailText;
                    webExceptionHandlingService.displaySimpleErrorNotification(response.data.Failure.Message, stackTrace);
                }
                return response;
            },
            'responseError': function (rejection) {

                var progressService = $injector.get('progressService');
                progressService.close();

                switch (rejection.status) {
                case 0:
                case 400:
                case 403:
                case 404:
                    break;
                case 401:
                    var authenticationService = $injector.get("authenticationService");
                    authenticationService.logout();
                    break;
                case 409:
                    var errorMessageObject = JSON.parse(rejection.statusText);
                    showOrHideThief(errorMessageObject.Reason);
                    break;
                case 500:
                case 502:
                    manageOfInternalServerError(rejection);
                    break;
                default:
                    break;
                }

                return $q.reject(rejection);
            }
        };

        return service;
    }
]);
